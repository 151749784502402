import { AdminCompanyDto } from './Company';
import { Workflow } from './Workflow';

export class User {
  static getUserFullName(user: User): string {
    return `${user.firstName} ${user.lastName}`;
  }

  id: string;
  email: string;
  name: string;
  aclId: string;
  language: string;
  firstName: string;
  lastName: string;
  image: string;
  title: string;
  phone: string;
  memberSince: Date;
  companies: AdminCompanyDto[];
  currentCompany?: AdminCompanyDto | null;
  workflows: Workflow[];
}

export class UserDto {
  id: string;
  firstName: string;
  lastName: string;
  image: string;
}

export class UpdateUserDTO {
  firstName: string;
  lastName: string;
  phone: string;
}

export enum InvitationStatusEnum {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
}

export class GetCompanyUsersDTO {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  phone: string;
  language: string;
  timezone: string;
  status: string;
  avatar: string;
  invitationId?: string;
  invitationStatus: InvitationStatusEnum;
}
