import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';

import { Search, Building } from 'lucide-react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { debounce, omit } from 'lodash';
import PropTypes from 'prop-types';
import AutoSuggest from 'react-autosuggest';
import { useTranslation } from 'react-i18next';
import Truncate from 'react-truncate';

import { Dropdown } from 'components/_common/Dropdown';
import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import { RouterContext } from 'components/_context/RouterContext';
import GalleryImage from 'components/_images/GalleryImage';
import styles from 'components/SearchBar/SearchBar.module.scss';
import smallTheme from 'components/SearchBar/smallTheme.module.scss';
import theme from 'components/SearchBar/theme.module.scss';
import Fetch from 'services/Fetch';
import { useGetLocationByCodeQuery, useGetAllCountriesQuery } from 'store/api';
import { countryMeta } from 'utils/localeConstants';
import { companyPath } from 'utils/routes';
import { OfficeType } from 'store/models/Company';
import { Badge } from '_ui/_common/Badge';

const propTypes = {
  small: PropTypes.bool,
};

const SearchBar = ({ small }) => {
  const { t } = useTranslation('common');

  const { router } = useContext(RouterContext);
  const { language, getSlug, getTranslated, domainCountryMeta, getSearchPath, domainLocation } =
    useContext(InternationalizationContext);

  const [value, setValue] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('all');
  const [currentSuggestion, setCurrentSuggestion] = useState({});
  const [suggestions, setSuggestions] = useState({ industries: [], companies: [], locations: [] });

  const selectedCountryIso3 = countryMeta[selectedCountry]?.iso3;

  const { data: selectedCountryLocation, isFetching: isFetchingLocation } = useGetLocationByCodeQuery(
    { locationCode: selectedCountryIso3 },
    {
      skip:
        !selectedCountry ||
        !selectedCountryIso3 ||
        selectedCountry === 'all' ||
        domainCountryMeta?.iso3 === selectedCountry,
    },
  );

  const { data: allCountries = [[]], isFetching: isFetchingAllCountries } = useGetAllCountriesQuery({
    extended: false,
    language,
  });

  const countriesList = useMemo(() => {
    return [
      { value: 'all', label: t('all countries') },
      ...allCountries[0].map((country) => {
        return { value: country.iso3, label: country.fullName[language] };
      }),
    ];
  }, [allCountries, t, language]);

  let suggestionSelected = false;

  useEffect(() => {
    if (router?.query?.q) {
      setValue(router.query.q);
    }
  }, [router.query.q]);

  useEffect(() => {
    if (domainLocation) {
      const matchedCountry = countriesList.find(
        ({ value }) =>
          getTranslated(countryMeta[value], 'description') === getTranslated(domainLocation, 'description'),
      );
      setSelectedCountry(matchedCountry?.value || 'all');
    }
  }, [countriesList, domainLocation, getTranslated]);

  // Fetch the respective suggestion according what was typed
  const fetchAndSaveSuggestions = useCallback(
    async (suggestionValue = '') => {
      const result = await Fetch.getJSON({
        url: `/suggestions?q=${suggestionValue}&size=4${
          selectedCountryLocation?.id ? `&location=${selectedCountryLocation.id}` : ''
        }`,
        language,
      });

      setSuggestions({
        ...suggestions,
        industries: result.industries?.slice(0, 5),
        companies: result?.companies,
      });
    },
    [language, selectedCountryLocation, suggestions],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSuggestionsFetchRequested = useCallback(
    debounce(({ value: suggestionValue }) => fetchAndSaveSuggestions(suggestionValue), 600),
    [fetchAndSaveSuggestions],
  );

  // Format suggestion
  const formatSuggestions = () => {
    const companySuggestionsSection = {
      title: t('company search'),
      suggestions: suggestions.companies,
    };

    const industrySuggestionsSection = {
      title: t('industry search'),
      suggestions: suggestions.industries,
    };

    return [industrySuggestionsSection, companySuggestionsSection];
  };

  // Calculate the input value to populate the input for the clicked suggestion.
  const getSuggestionValue = (suggestion) => {
    setCurrentSuggestion(suggestion);

    return getTranslated(suggestion, 'name');
  };

  // Define how to render suggestions.
  const renderSuggestion = (suggestion, { query }) => {
    const suggestionText = getTranslated(suggestion, 'name');
    const matches = match(suggestionText, query);
    const parts = parse(suggestionText, matches);
    const isCompany = suggestion?.id?.length > 8;

    return (
      <div className="flex items-baseline">
        <div className="flex-none w-4">
          {isCompany &&
            (suggestion.logo ? (
              <GalleryImage
                path={suggestion.logo}
                width={16}
                height={16}
                className={`h-full ${styles.company_icon} inline-block`}
                style={{
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
              />
            ) : (
              <Building className="h-4 w-4 mr-3 text-gray-400" />
            ))}
        </div>
        <div className="flex flex-col ml-2">
          <div className={`flex ${small ? 'flex-col' : 'flex-row'}`}>
            {suggestion?.officeType === OfficeType.BRANCH && (
              <div>
                <Badge color="gray" className="mr-2">
                  {t('branch')}
                </Badge>
              </div>
            )}
            <div className="inline-block text-base leading-6">
              {parts.map((part) => {
                const className = part.highlight ? 'highlight' : 'regular';
                return (
                  <span className={styles[className]} key={part.text}>
                    {part.text}
                  </span>
                );
              })}
            </div>
          </div>
          <ul className="flex flex-row">
            <il className={`text-base font-normal leading-6 text-gray-500 ${suggestion.tradeName ? 'mr-1' : ''}`}>
              {suggestion.tradeName}
            </il>
            {suggestion?.officeType === OfficeType.BRANCH && (
              <il
                className={`text-base font-normal leading-6 text-gray-500 list-disc ${
                  suggestion.tradeName ? 'list-item ml-4' : ''
                }`}
              >
                <p title={suggestion.legalName}>
                  <Truncate>{suggestion.location}</Truncate>
                </p>
              </il>
            )}
          </ul>
        </div>
      </div>
    );
  };

  const renderSectionTitle = () => null;

  const getSectionSuggestions = (section) => section.suggestions;

  const onChange = (_event, { newValue }) => {
    setValue(newValue);
  };

  const onKeyDown = (event) => {
    const { keyCode } = event;

    if (keyCode === 13 && !suggestionSelected) {
      // omit empty string
      const query = value === '' ? {} : { q: value };

      // optional chaining is needed
      // selectedCountryLocation will be undefined if all countries are selected
      // check call to useGetLocationByCodeQuery

      // TODO fix this
      // navigation relies on API call (selectedCountryLocation)
      // if you click fast enough it won't navigate, or it will navigate to wrong place
      const location = selectedCountryLocation?.slug?.[language] || router.query.location;

      if (location) {
        query.location = location;
      }

      if (router.query.industry) {
        query.industry = router.query.industry;
      }

      // remove location from query, query.location is used to get the slug
      router.push({ pathname: getSearchPath(query), query: omit(query, 'location') });

      setSuggestions({ industries: [], companies: [], locations: [] });
    }

    suggestionSelected = false;
  };

  // Pass through arbitrary props to the input. It must contain at least value and onChange.
  const inputProps = {
    'data-testid': 'search-bar',
    placeholder: small ? t('search') : t('search_by'),
    value,
    onChange,
    onKeyDown,
    autoComplete: 'chrome-off',
  };

  // Autosuggest will call this function every time suggestions should be cleared.
  const onSuggestionsClearRequested = () => {
    setSuggestions({ industries: [], companies: [], locations: [] });
  };

  const onSuggestionSelected = (event, { suggestion, sectionIndex }) => {
    suggestionSelected = true;

    if (sectionIndex === 1) {
      window?.analytics?.track('company_profile--search--clicked', { source: 'Search bar', company: suggestion.id });
      router.push(companyPath(suggestion.id, getSlug(suggestion)));
    } else {
      router.push({
        pathname: getSearchPath({
          industry: getSlug(suggestion),
          location: selectedCountryLocation?.id,
          title: getTranslated(suggestion, 'name'),
        }),
      });
    }
  };

  const searchIconClicked = () => {
    // omit empty string
    const query = value === '' ? {} : { q: value };

    // optional chaining is needed
    // selectedCountryLocation will be undefined if all countries are selected
    // check call to useGetLocationByCodeQuery

    // TODO fix this
    // navigation relies on API call (selectedCountryLocation)
    // if you click fast enough it won't navigate, or it will navigate to wrong place
    const location = selectedCountryLocation?.slug?.[language] || router.query.location;

    if (location) {
      query.location = location;
    }

    if (router.query.industry) {
      query.industry = router.query.industry;
    }

    if (!suggestionSelected) {
      // remove location from query, query.location is used to get the slug
      router.push({ pathname: getSearchPath(query), query: omit(query, 'location') });
    } else {
      onSuggestionSelected('', { suggestion: currentSuggestion });
    }

    suggestionSelected = false;
  };

  return (
    <div className={styles.searchBarContainer}>
      <AutoSuggest
        theme={small ? smallTheme : theme}
        suggestions={formatSuggestions()}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        multiSection
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
      />
      <div className={styles.search_bar__actions_container}>
        {!small && (
          <>
            <div className={styles.vl} />

            <Dropdown className="ml-4 mr-2">
              <Dropdown.Button variant="text-dark" disabled={isFetchingLocation || isFetchingAllCountries}>
                <div className="mr-2 truncate max-w-searchContainerCountry-sm xl:max-w-searchContainerCountry-xl">
                  {countriesList.find(({ value }) => value === selectedCountry)?.label}
                </div>
              </Dropdown.Button>
              <Dropdown.Items>
                {countriesList
                  .filter(({ value }) => value !== selectedCountry)
                  .map(({ value, label }) => (
                    <Dropdown.Item key={value} content={label} onClick={() => setSelectedCountry(value)} />
                  ))}
              </Dropdown.Items>
            </Dropdown>
          </>
        )}

        <button
          type="button"
          onClick={searchIconClicked}
          className={styles.searchIcon}
          disabled={isFetchingLocation}
          data-testid="search_bar--search_button"
        >
          <Search className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

SearchBar.propTypes = propTypes;

export default SearchBar;
