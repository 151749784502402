import React, { useContext, useCallback, useEffect, useState } from 'react';

import { ArrowBigRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import Column from 'components/_designSystem/_layout/Column';
import Container from 'components/_designSystem/_layout/Container';
import Grid from 'components/_designSystem/_layout/Grid';
import styles from 'components/_layouts/Footer/FooterMenus/FooterMenus.module.scss';
import ListItems from 'components/_listItems/ListItems';
import Link from 'components/Link';
import { useGetAllCountriesQuery } from 'store/api';

import { footerSubjects } from './footer-subjects';

const MAX_LOCATION_ITEMS = 6;
const MAX_INDUSTRY_ITEMS = 18;

const FooterMenus = (): React.JSX.Element => {
  const { t } = useTranslation();

  const [industries, setIndustries] = useState<{ value: string; href: string }[]>([]);
  const [loadingIndustries, setLoadingIndustries] = useState(true);

  const { getTranslated, getSlug, language } = useContext(InternationalizationContext);

  const { data: [locations] = [[]] } = useGetAllCountriesQuery({
    extended: false,
  });

  const fetchIndustries = async () => {
    setLoadingIndustries(true);
    try {
      const selectedIndustries = footerSubjects.sort(() => 0.5 - Math.random()).slice(0, MAX_INDUSTRY_ITEMS);
      setIndustries(
        selectedIndustries.map((industry) => ({
          value: getTranslated(industry, 'description'),
          href: `/companies/industries/${getSlug(industry, language)}`,
        })),
      );
    } catch (error) {
    } finally {
      setLoadingIndustries(false);
    }
  };

  useEffect(() => {
    fetchIndustries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const formatLocations = useCallback(
    (locations) =>
      locations
        .map((l) => l)
        .sort((a: { indexedCompanies: string }, b: { indexedCompanies: string }) => {
          // Attempt to cast to numbers, handle possible errors, and use a fallback
          const aIndexedCompanies = Number(a.indexedCompanies);
          const bIndexedCompanies = Number(b.indexedCompanies);

          // Check if the casting was successful
          if (isNaN(aIndexedCompanies) && isNaN(bIndexedCompanies)) {
            // If both are NaN, consider them equal
            return 0;
          } else if (isNaN(aIndexedCompanies)) {
            // If only aIndexedCompanies is NaN, consider it smaller
            return 1;
          } else if (isNaN(bIndexedCompanies)) {
            // If only bIndexedCompanies is NaN, consider it smaller
            return -1;
          } else {
            // If both are valid numbers, proceed with the comparison
            return bIndexedCompanies - aIndexedCompanies;
          }
        })
        .slice(0, MAX_LOCATION_ITEMS)
        .map((location) => ({
          value: getTranslated(location, 'description'),
          href: `/companies/locations/${getSlug(location, language)}`,
        })),
    [getSlug, getTranslated, language],
  );

  return (
    <Container className={styles.Footer__lists}>
      <Grid className="gap-y-8">
        <Column col={12} md={9}>
          <ListItems
            cols={3}
            title={t('top industries')}
            values={loadingIndustries ? [] : industries}
            footer={
              <Link
                href="/browse-industries"
                className="group flex flex-row items-center text-white hover:text-gray-300"
                data-testid="footer--view_all_industries_link"
              >
                {t('view all')}
                <ArrowBigRight className="ml-2 w-4 h-4 group-hover:text-gray-300 scale-y-75 scale-x-150 fill-current" />
              </Link>
            }
          />
        </Column>
        <Column col={12} md={3}>
          <ListItems
            cols={1}
            title={t('locations')}
            values={formatLocations(locations)}
            footer={
              <Link
                href="/browse-locations"
                className="group flex flex-row items-center text-white hover:text-gray-300"
                data-testid="footer--view_all_locations_link"
              >
                {t('view all')}
                <ArrowBigRight className="ml-2 w-4 h-4 group-hover:text-gray-300 scale-y-75 scale-x-150 fill-current" />
              </Link>
            }
          />
        </Column>
      </Grid>
    </Container>
  );
};

export { FooterMenus };
